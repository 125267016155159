.blog {


    .custom-fa-angle-right {
        &:after {
            transition: all 0.3s ease-out;
            position: absolute;
            content: "";
            border-left: 2px solid whitesmoke;
            border-bottom: 2px solid whitesmoke;
            width: 10px;
            height: 10px;
            transform: rotate(-135deg);
            margin-top: -5px;
            margin-left: -6px;
        }
    }

    .custom-fa-angle-left {
        &:after {
            transition: all 0.3s ease-out;
            position: absolute;
            content: "";
            border-left: 2px solid whitesmoke;
            border-bottom: 2px solid whitesmoke;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            margin-top: -5px;
            margin-left: -3px;
        }
    }


    @extend %of-hidden,
    %p-rel;

    &__wrapper {
        @extend %p-rel;
        z-index: 2;
    }

    &__bg {
        &-element {
            img {
                @extend %w-100,
                %p-abs;
                left: 0;
                top: 0;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                opacity: 0.8;
            }
        }
    }

    &__item {
        &-inner {
            @extend %px-25,
            %pt-25,
            %pb-30,
            %of-hidden;
            @include add-prefix(transition, $transition);
            background-color: $wh-color;
            border: 2px solid transparent;
            // todo
            box-shadow: 0px 16px 48px 0px rgba(6, 35, 63, 0.03), 0px -16px 48px 0px rgba(6, 35, 63, 0.03);
            border-radius: 16px;

            &:hover {
                border: 2px solid $brand-color;
                box-shadow: none;

                .blog {
                    &__thumb {
                        img {
                            @include add-prefix(transform, scale(1.05));
                        }
                    }

                    &__content {
                        h5 {
                            color: $brand-color;
                        }
                    }
                }

            }

            &--style2 {
                border: none;

                &:hover {
                    border: none;
                    box-shadow: 0px 8px 16px rgba(3, 23, 41, 0.08);

                    .blog {
                        &__thumb {
                            img {
                                @include add-prefix(transform, scale(1.05));
                            }
                        }

                        &__content {
                            h5 {
                                color: $secondary-color;
                            }
                        }
                    }

                }
            }


        }

        &--style2 {
            @extend %pb-35;

        }


        &--style3 {
            .blog__item {
                &-inner {
                    &:hover {
                        border: 2px solid $tertiary-color4;

                        .blog {
                            &__content {
                                h5 {
                                    a {
                                        color: $tertiary-color4;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


    }

    &__thumb {
        @extend %of-hidden;
        border-radius: 8px;

        img {
            @extend %w-100;
            @include add-prefix(transition, $transition);
        }
    }

    &__content {
        h5 {
            @extend %text-semibold,
            %text-capitalize,
            %text-clamp-1;
            @include add-prefix(transition, $transition);


            &.style2 {
                &:hover {
                    a {
                        color: $secondary-color;
                    }
                }
            }

        }

        p {
            @extend %text-clamp-3;
        }
    }

    &__writer {
        @extend %d-flex,
        %align-i-center;
        gap: 10px;

        &-thumb {
            img {
                border-radius: 99px;
                max-width: 50px;
            }
        }


        &-designation {
            h6 {
                @extend %text-semibold;
                @include font-size(16px);
                font-family: $text-font;
                color: $title-color;
            }

            p {
                @extend %text-semibold;
                @include font-size(18px);
                color: $title-color;
                margin-bottom: -3px;
            }

            span {
                @include font-size(14px);
                color: $text-color;
            }
        }
    }

    &__meta {
        @extend %py-15;

        @include breakpoint(xl) {
            padding-block: 16px;
        }

        &-tag {
            @extend %d-inline-block,
            %text-semibold;
            @include font-size(14px);
            padding: 3px 12px;
            border-radius: 8px;
            text-transform: capitalize;
            // background-color: rgba($brand-color-1, $alpha: .1);

            &--style1 {
                // trk-rgba 
                color: $secondary-color;
                background-color: $bg-color-7;
            }

            &--style2 {
                color: $brand-color;
                background-color: $tertiary-color;
            }
        }
    }



    // footer shape

    &__shape {

        &-item {
            @extend %p-abs;

            &--1 {
                content: "";
                // border: 2px solid #B5CAF2;
                border: 2px solid var(--bg-grad-color);
                padding: 40px;
                border-radius: 100px 100px 229px 100px;
                transform: rotate(65deg);
                border-top: transparent;
                display: none;

                @include breakpoint(md) {
                    display: block;
                    height: 80%;
                    width: 1000px;
                    top: -14%;
                    left: -36%;
                }

                @include breakpoint(lg) {
                    top: -6%;
                    left: -28%;
                }

                @include breakpoint(xl) {
                    height: 95%;
                    top: -17%;
                    left: -10%;
                }

                @include breakpoint(xxl) {
                    top: -17%;
                    left: 0%;
                }


                span {
                    position: absolute;
                    content: "";
                    height: 94%;
                    width: 980px;
                    border-radius: 100px 100px 236px 100px;
                    background: linear-gradient(180deg, var(--bg-grad-color-trans) 0%, var(--bg-grad-color) 100%);
                    opacity: 0.6;

                    @include breakpoint(md) {
                        top: 3%;
                        left: -1%;
                    }
                }


            }
        }

    }



}